.App {
  margin: 10px 10px 10px 10px;
}

.train-styling {
  border-radius: 100%;
  padding: 5px 8px 5px 8px;
  background-color: red;
  color: white;
  font-family: Helvetica,Arial, sans-serif;
}

.subway-line {
  margin: 20px 20px 20px 0px;
}

.content-block {
  font-size: 1.3em;
}

.individual-arrival {
  margin-left: 5px
}

.direction {
  font-weight: bold;
  margin: 0px 10px 0px 10px;
}